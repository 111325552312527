/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen
// npm install npm start npm run build


const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Wenjie Wu",
  title: "我是吴文洁,欢迎来到我的个人主页！",
  subTitle: emoji(
    " 🚀 我是一名金融从业者、人工智能初级工程师、❤️ Linux用户、独立网站拥有者"
  ),
  resumeLink:
    "https://www.wuwenjie.xyz/wordpress/", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/wuwenjie1992",
  facebook: "https://www.zhihu.com/people/wuwenjie1992/",
  gmail: "wuwenjie0605@gmail.com",
  //gitlab: "https://gitlab.com/saadpasta",
  //linkedin: "https://www.linkedin.com/in/saadpasta/",
  //medium: "https://medium.com/@saadpasta",
  //stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "我在做什么",
  subTitle: "金融行业从业者、机器学习初级工程师、Linux用户、独立网站拥有者",
  skills: [
    emoji("⚡ 实践机器学习、人工智能技术在金融行业的应用"),
    emoji("⚡ 为各种信贷资产进行信用风险建模评估，如不良率、滚动率预测"),
    emoji("⚡ AI机器人在风控、营销等领域的应用")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "linux",
      fontAwesomeClassname: "fab fa-linux"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
	{
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
	{
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "php",
      fontAwesomeClassname: "fab fa-php"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};



// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "数据统计分析", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
	{
      Stack: "SQL",
      progressPercentage: "90%"
    },
    {
      Stack: "Python",
      progressPercentage: "70%"
    },
    {
      Stack: "数据建模",
      progressPercentage: "60%"
    },
    {
      Stack: "人工智能",
      progressPercentage: "50%"
    }
  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "上海xx大学",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "位于上海松江区的一所普通综合性大学",
      duration: "Sep 2010 - Jul 2014",
      desc: "金融学学士-4年全日制",
      descBullets: [
        "主要课程：微观经济学、证券投资、会计学、数据库原理、高等数学、数理统计等",
      ]
    },
    {
      schoolName: "上海xx中学",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "上海北部XX区的一所历史悠久的区重点中学。",
      duration: "Sep 2007 - Sep 2010",
      desc: "",
      descBullets: ["选修物理"]
    }
  ]
};


// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "业务副经理",
      company: "上X银行XX中心",
      companylogo: require("./assets/images/facebookLogo.png"),
      date: "201707 – Present",
      desc: "上X银行XX中心是中等规模的XX银行。",
      descBullets: [
        "多年连续获得A等考评，多次获得总行一级部门荣誉及二级内设部荣誉。",
        "2017年三级业务员入职",
        "税后收入：X0万"
      ]
    },
    {
      role: "风控系统管理助理",
      company: "招X银行XX中心",
      companylogo: require("./assets/images/quoraLogo.png"),
      date: "201408 – 201707",
      desc: "XX银行XX中心是业内第一的标杆，职务是风控系统管理助理",
      descBullets: [
        "本科毕业入职",
        "获得爱岗敬业奖",
        "税后收入：7万"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "大项目",
  subtitle: "个人参与的重大项目",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "xx银行智能外呼平台",
      projectDesc: "2022年开始，xx银行与中国平安战略合作的契机，参与xx银行全行级别的AI智能外呼平台的建设，每天可以提供几十万的外呼能力。应用该平台进行风险控制、产品营销的业务，为xx银行每年降本增效9X万元。",
      footerLink: [
        {
          name: "更多内容",
          url: ""
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      projectName: "XX银行XX中心xx部作业平台",
      projectDesc: "使用php、myql、redis等技术，开发并提供部门内部表单登记、短信、邮件发送、流程审批等功能，累计已有数百用户，总访问量达百万次。",
      footerLink: [
        {
          name: "更多内容",
          url: ""
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("荣誉和认证 🏆 "),
  subtitle:
    "成就，认证，奖励信和一些我做过的很酷的事情！",

  achievementsCards: [
    {
      title: "XX银行XX中心优秀员工 ",
      subtitle:
        "2022年度XX银行XX中心优秀员工，此前连续多年获得A等考核。",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "XX银行XX中心优秀员工",
      footerLink: [
        {
          name: "more",
          url: ""
        }
      ]
    },
    {
      title: "XX银行XX中心优秀项目",
      subtitle:
        "XX银行XX中心优秀项目",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "XX银行XX中心优秀项目 ",
      footerLink: [
        {
          name: "more",
          url: "https://"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "技术博客",
  subtitle:
    "了解我近期的所思所想",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  
  blogs: [
  {
      url: "https://zhuanlan.zhihu.com/p/968862860",
      title: "如何实现一个类似openai o1草莓🍓大模型？",
      description:
        "由于o1模型内部机制没有公开太多内容，外界猜测可能是多个模型组合起来实现的。本文将基于大模型agent的思路来实现类似草莓的推理功能。采用的主要手段是LLM api + COT推理（ReAct ）+ 反思 reflection + 言语强化学习（verbal rl）。。。。。。"
    },
    {
      url: "https://zhuanlan.zhihu.com/p/691150908",
      title: "智赢未来，RAG驱动下一代企业级智能问答的革新之路",
      description:
        "检索增强生成（RAG）技术在企业级智能问答中的应用。RAG能够整合外部数据库的知识，提高大型语言模型生成内容的准确性和可信度。详细介绍了RAG的实现方式，包括使用LangChain、llama_index等框架，以及不同RAG方法的效果对比。。。。。"
    },
    
    {
      url: "https://zhuanlan.zhihu.com/p/638916832",
      title: "面向金融消费者问答的大语言模型微调技术实践",
      description:
        "金融消费者问答是用户指针对金融产品、服务、政策等方面的提出问题，金融机构的专业人士或其他用户给出建议或回答。它可以促进金融市场的信息流通和透明度。。。"
    },
    {
      url: "https://zhuanlan.zhihu.com/p/590349066",
      title: "拥有自己的ChatGPT太遥远？不如现搭一个中文闲聊GPT-2模型",
      description:
        "2022年最火的应该是AI 数字绘画 Stable diffusion，本文封面即为AI生成。但没想到在短短几天内OpenAI的ChatGPT从国外火到国内。ChatGPT仅用了5天的时间用户破就百万了，在后面几天ChatGPT注册爆满，服务火爆也发生了宕机。"
    },
	{
      url: "https://zhuanlan.zhihu.com/p/443782891",
      title: "详解小样本短文本多分类-对比TextCNN与ALBERT分类效果的实践（附Pytorch代码）",
      description:
        "文本分类（text classification）是自然语言处理（NLP）中应用最广泛的任务之一。如新闻分类、垃圾邮件分类、情感分类等，这些是最常见的文本分类实际应用。更多的应用场景比如：电商评价分类、问答内容分类、股市消息利好利坏分类。。。"
    },
	{
      url: "https://zhuanlan.zhihu.com/p/351678987",
      title: "基于双塔SBERT模型的智能语义计算实验",
      description:
        "在自然语言处理NLP中，文本分类、聚类、表示学习、向量化、语义相似度或者说是文本相似度等等都有非常重要的应用价值。这些任务都是为了能从复杂的文本中，通过采用ML/DL的方法，学习到本文深层次的语义表示，从而更好地服务于下游任务（分类、聚类、相似度等）。这里笔者将这些统一概括为智能语义计算。。。"
    },
	{
      url: "https://zhuanlan.zhihu.com/p/186211797",
      title: "Google无监督数据增强方法UDA在文本分类上的实验",
      description:
        "在实际的场景下，UDA代表的半监督学习有十分广大的应用场景。例如，在某个细分领域，如金融领域，涵盖了大量的财经新闻、公司财报、法律文书、客户沟通记录等等，在该领域下没有标记的原始文本数据非常的庞大。。。"
    },
	{
      url: "https://www.wuwenjie.xyz/smart/A_Survey_of_Large_Language_Models.html",
      title: "大语言模型综述-v11 中英文机器翻译对照版",
      description:
        "来自RUC的非常全面而具体的大语言模型综述文章，基本涵盖了大模型的方方面面，是非常好的学习大模型的材料。综述论文仍在持续更新中。。。"
    },
	{
      url: "https://www.wuwenjie.xyz/wordpress/",
      title: "既智新闻",
      description:
        "记录不该被忘记的消息。。。"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build ",
      subtitle: "Codelab ",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("播客 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("联系我 ☎️"),
  subtitle: "有想合作的项目？",
  number: "+86-0000000000",
  email_address: "wuwenjie0605@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
